<script setup lang="ts">
import { useFuse } from '@vueuse/integrations/useFuse'
import type { IForecastAssumption } from '@reports'

const props = withDefaults(
  defineProps<{ assumptions: IForecastAssumption[]; loading: boolean }>(),
  {
    assumptions: () => [],
    loading: false,
  },
)

const emit = defineEmits<{
  click: [assumption: IForecastAssumption]
}>()

const search = ref('')
const { results } = useFuse(
  search,
  computed(() => props.assumptions),
  {
    matchAllWhenSearchEmpty: true,
    fuseOptions: {
      keys: ['leaseName'],
      threshold: 0.3,
      includeMatches: true,
      findAllMatches: false,
      shouldSort: true,
    },
  },
)
const filteredResults = computed(() =>
  results.value.map<IForecastAssumption>((result) => result.item),
)
</script>

<template>
  <Scrollbar class="absolute inset-0 w-full">
    <div class="w-full p-6">
      <FormKit
        v-model="search"
        :disabled="loading"
        type="search"
        placeholder="Search for leases"
        outer-class="w-full mb-2"
        :delay="150"
      >
        <template #prefix>
          <Icon name="search" class="text-gray-350 ml-2" filled />
        </template>
      </FormKit>
      <template v-if="loading">
        <Skeleton :width="120" :height="26" />
        <Skeleton :width="240" :height="22" class="mt-1" />
      </template>
      <template v-else>
        <h2 v-if="search.length > 0">
          Filtering leases {{ filteredResults.length }}
        </h2>
        <h2 v-else>{{ results.length }} leases:</h2>
        <p>Select a lease to view its details.</p>
      </template>
    </div>
    <div class="mb-10 mt-4 flex w-full flex-col divide-y divide-gray-700/50">
      <template v-if="loading">
        <Button
          v-for="idx in 10"
          :key="idx"
          color="link"
          class="z-10 line-clamp-2 flex items-center justify-between rounded-none px-6 py-4 text-left"
          :disabled="true"
        >
          <Skeleton :width="200" :height="20" />
          <Icon name="chevron-right" class="opacity-50" />
        </Button>
      </template>
      <template v-else>
        <Button
          v-for="assumption in filteredResults"
          :key="assumption.leaseId"
          color="link"
          class="z-10 line-clamp-2 flex items-center justify-between rounded-none px-6 py-4 text-left hover:bg-gray-800 hover:no-underline"
          @click="emit('click', assumption)"
        >
          {{ assumption.leaseName }}
          <Icon name="chevron-right" />
        </Button>
      </template>
    </div>
  </Scrollbar>
</template>
